import { inject, Injectable } from '@angular/core';
import { collection, Firestore } from '@angular/fire/firestore';

import { PriceGroup } from 'app/core/models';
import { FirestoreHelperService } from 'app/core/services';

@Injectable({
  providedIn: 'root'
})
export class PriceGroupFirestoreService {
  private firestore = inject(Firestore);
  private firestoreHelper = inject(FirestoreHelperService);

  watchPriceGroups() {
    return this.firestoreHelper.colData<PriceGroup>(collection(this.firestore, 'price_group'));
  }
}
