import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { PriceGroup } from 'app/core/models';
import { AuthService } from 'app/core/services';
import { combineLatest, pipe, switchMap } from 'rxjs';
import { PriceGroupFirestoreService } from '../services/price-group-firestore.service';

interface PriceGroupState {
  priceGroups: PriceGroup[];
};

const initialState: PriceGroupState = {
  priceGroups: [],
};

export const PriceGroupStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, firestoreService = inject(PriceGroupFirestoreService), authService = inject(AuthService)) => (
    {
      watchPriceGroups: rxMethod<void>(
        pipe(
          switchMap(() => combineLatest([authService.authState()]).pipe(
            switchMap(([user]) => {
              if (user == null) {
                patchState(store, initialState);
                return [];
              }
              return firestoreService.watchPriceGroups().pipe(
                tapResponse({
                  next: (result: PriceGroup[]) => patchState(store, {
                    priceGroups: result || [],
                  }),
                  error: (err) => {
                    console.error('Failed to load price groups', err);
                  }
                })
              );
            }),
          )
          )
        )
      ),
    })
  )
);
